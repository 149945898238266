// Generated by Framer (5c93d09)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, Text, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {pDT716Hhz: {hover: true}};

const cycleOrder = ["pDT716Hhz"];

const serializationHash = "framer-xk4TQ"

const variantClassNames = {pDT716Hhz: "framer-v-2d2o5f"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, title, width, ...props}) => { return {...props, nQXcBjQzh: title ?? props.nQXcBjQzh ?? "Influencer Marketing"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, nQXcBjQzh, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "pDT716Hhz", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (gestureVariant === "pDT716Hhz-hover") return true
return false
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-xk4TQ", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-2d2o5f", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"pDT716Hhz"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"pDT716Hhz-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Text __fromCanvasComponent alignment={"left"} className={"framer-fvilg"} data-framer-name={"Influencer Marketing"} fonts={["CUSTOM;Altone Medium"]} layoutDependency={layoutDependency} layoutId={"aW5JfncPI"} rawHTML={"<span style='font-size: 0; line-height: 0; tab-size: 4; white-space: inherit; word-wrap: inherit'><span style='font-size: 0'><span style=''>Influencer Marketing</span><br></span></span>"} style={{"--framer-font-family": "\"Altone Medium\", \"Altone Medium Placeholder\", sans-serif", "--framer-font-size": "20px", "--framer-font-style": "normal", "--framer-font-weight": 400, "--framer-letter-spacing": "0em", "--framer-line-height": "24px", "--framer-text-alignment": "left", "--framer-text-color": "rgb(255, 255, 255)", "--framer-text-decoration": "none", "--framer-text-transform": "capitalize"}} text={nQXcBjQzh} variants={{"pDT716Hhz-hover": {"--framer-text-color": "var(--token-e938dcc6-2a0e-4c12-9846-4efab72d34e8, rgb(228, 2, 70))"}}} verticalAlignment={"center"} withExternalLayout/>{isDisplayed() && (<SVG className={"framer-vo1ssw"} data-framer-name={"Line 2"} fill={"rgba(0,0,0,1)"} intrinsicHeight={23} intrinsicWidth={7} layoutDependency={layoutDependency} layoutId={"TOvxAx75L"} svg={"<svg width=\"7\" height=\"23\" viewBox=\"-2 -2 7 23\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<line x1=\"1.25\" y1=\"1.25\" x2=\"1.25\" y2=\"17.75\" stroke=\"white\" stroke-width=\"2.5\" stroke-linecap=\"round\"/>\n</svg>\n"} withExternalLayout/>)}</motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-xk4TQ [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-xk4TQ .framer-4ehit0 { display: block; }", ".framer-xk4TQ .framer-2d2o5f { height: 26px; overflow: hidden; position: relative; width: 251px; }", ".framer-xk4TQ .framer-fvilg { bottom: 0px; flex: none; left: 0px; overflow: hidden; position: absolute; right: 0px; top: 0px; white-space: pre-wrap; word-break: break-word; word-wrap: break-word; }", ".framer-xk4TQ .framer-vo1ssw { flex: none; height: 23px; left: 0px; position: absolute; top: 0px; width: 7px; }", ".framer-xk4TQ .framer-v-2d2o5f .framer-2d2o5f { cursor: pointer; }", ".framer-xk4TQ.framer-v-2d2o5f.hover .framer-fvilg { left: unset; right: -15px; width: 251px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 26
 * @framerIntrinsicWidth 251
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"vopG1lYWV":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"nQXcBjQzh":"title"}
 * @framerImmutableVariables true
 */
const FramerPubaCiz9w: React.ComponentType<Props> = withCSS(Component, css, "framer-xk4TQ") as typeof Component;
export default FramerPubaCiz9w;

FramerPubaCiz9w.displayName = "Archive / Influencer Marketing tab";

FramerPubaCiz9w.defaultProps = {height: 26, width: 251};

addPropertyControls(FramerPubaCiz9w, {nQXcBjQzh: {defaultValue: "Influencer Marketing", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerPubaCiz9w, [{family: "Altone Medium", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/PubaCiz9w:default", url: "https://framerusercontent.com/assets/cSj6ZY9zQbC02MkWJ6N0nfdOzrE.ttf"}, url: "https://framerusercontent.com/assets/cSj6ZY9zQbC02MkWJ6N0nfdOzrE.ttf"}])